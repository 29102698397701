import qs from "qs";
import { useToken } from 'src/store';
import IHttpRequest from "./http-request";
import IHttpResponse from "./http-response";
import HttpResponseCode from "./http-response-code";
import Axios, { AxiosRequestConfig as requestConfig, AxiosResponse } from "axios";
import router from "src/router";
import parse from './json-parse/json_parse';
import { Type, EnumUtils, tools } from "../utils";
interface IAxiosRequestConfig extends requestConfig {
    $request: IHttpRequest;
}

/**
 * 提供常用 HTTP 请求方式的封装。
 * @class
 * @version 1.0.0
 */
export default class HttpClient
{
    
    // 全局响应拦截
    private _handlers: Set<(code: number, response: IHttpResponse, request: IHttpRequest) => void | Promise<void>>;

    // 静态单例
    private static _instance: HttpClient;

    // 静态单例
    public static count: number = 0;

    /**
     * 获取或设置全局请求配置。
     * @member
     * @returns string
     */
    public options: IHttpRequest =
    {
        // 参数序列化方式("form", "json", "form-data")。
        serializeType: "json",

        // 跨域处理
        withCredentials: false,

        // 超时时间
        timeout: 50000,

        // 请求头
        headers:
        {
           
        },

        interceptors:
        {
            request: null,          // 请求拦截器
            response: null          // 相应拦截器
        }
    };

    /**
     * 获取一个列表，包含所有全局响应处理程序(拦截器)。
     * @property
     * @param  {IHttpRequest} request
     */
    public get handlers(): Set<(code: number, response: IHttpResponse, request: IHttpRequest) => void | Promise<void>>
    {
        if(!this._handlers)
        {
            this._handlers = new Set();
        }

        return this._handlers;
    }

    /**
     * 获取网络请求客户端的单实例。
     * @static
     * @property
     * @returns HttpClient
     */
    public static get instance(): HttpClient
    {
        if(!this._instance)
        {
            this._instance = new HttpClient();
        }
        
        return this._instance;
    }

    /**
     * 发送一个 get 请求。
     * @async
     * @param  {IHttpRequest} request 请求实例。
     * @returns Promise
     */
    public async get(request: IHttpRequest): Promise<any>
    {
        let config = this.getAxiosRequest("get", request);
        let result = await this.send(config);

        return result;
    }

    /**
     * 发送一个 post 请求。
     * @async
     * @param  {IHttpRequest} request 请求实例。
     * @returns Promise
     */
    public async post(request: IHttpRequest): Promise<any>
    {
        let config = this.getAxiosRequest("post", request);
        let result = await this.send(config);

        return result;
    }

    /**
     * 发送一个 put 请求。
     * @async
     * @param  {IHttpRequest} request 请求实例。
     * @returns Promise
     */
    public async put(request: IHttpRequest): Promise<any>
    {
        let config = this.getAxiosRequest("put", request);
        let result = await this.send(config);

        return result;
    }

    /**
     * 发送一个 delete 请求。
     * @async
     * @param  {IHttpRequest} request 请求实例。
     * @returns Promise
     */
    public async delete(request: IHttpRequest): Promise<any>
    {
        let config = this.getAxiosRequest("delete", request);
        let result = await this.send(config);

        return result;
    }

    /**
     * 发送一个 patch 请求。
     * @async
     * @param  {IHttpRequest} request 请求实例。
     * @returns Promise
     */
    public async patch(request: IHttpRequest): Promise<any>
    {
        let config = this.getAxiosRequest("patch", request);
        let result = await this.send(config);

        return result;
    }

    /**
     * 发送一个文件上传请求。
     * @async
     * @param  {IHttpRequest} request 请求实例。
     * @returns Promise
     */
    public async upload(request: IHttpRequest): Promise<any>
    {
        if(!request.files || Object.keys(request.files).length === 0)
        {
            throw new Error("files is empty...");
        }

        const data = new FormData();

        // append params
        for(let key in request.data)
        {
            if(request.files.hasOwnProperty(key))
            {
                const value = request.files[key];
                data.append(key, value);
            }
        }
        
        // append files
        for(let name in request.files)
        {
            if(request.files.hasOwnProperty(name))
            {
                const file = request.files[name];
                data.append("file", file, file.name);
            }
        }

        request.data = data;

        let config = this.getAxiosRequest("post", request);
        let result = await this.send(config);

        return result;
    }

    /**
     * 根据指定的请求方式和选项生成一个 Axios 请求实例。
     * @param  {string} method 请求方式，如： 'get', 'post'。
     * @param  {IHttpRequestOptions} option 请求选项。
     * @returns IAxiosRequestConfig Axios 请求实例。
     */
    private getAxiosRequest(method: any, request: IHttpRequest): IAxiosRequestConfig
    {
        request.data = request.data || {};

        // 构造url(将url中需要传入参数的地方替换掉)
        let requestUrl = Type.isUndefined(request.urlPath) ?  request.url : tools.formatString(request.url && request.url || "", request.urlPath);

        // 构造url(支持请求中自定义headers)
        let requestHeader = Type.isUndefined(request.headers) ? this.options.headers : Object.assign({}, this.options.headers, request.headers);

        // 构造分页数据
        let requestQuerys = !Type.isUndefined(request.params) && request.params;

        // 构造服务器响应的数据类型。
        let responseType: any = !Type.isUndefined(request.responseType) && request.responseType;

        // 构造服务器响应的数据类型。
        let onUploadProgress: any = !Type.isUndefined(request.onUploadProgress) && request.onUploadProgress;

        // 构造服务器响应的数据类型。
        let onDownloadProgress: any = !Type.isUndefined(request.onDownloadProgress) && request.onDownloadProgress;
        
        // 请求实例
        let config: IAxiosRequestConfig =
        {
            url: requestUrl,
            method: method,
            // 支持请求中自定义headers
            headers: requestHeader,
            responseType,
            params: requestQuerys,
            $request: {},
            // 不同源请求是否携带凭证
            withCredentials: Type.isUndefined(request.withCredentials) ? this.options.withCredentials : request.withCredentials,
            transformResponse: [
                function (data) {
                    // transformResponse这个配置项可以拦截接口返回的内容进行处理
                    try {
                        // 如果大数字类型转换成功则返回转换的数据结果
                        return parse({storeAsString: true})(data, undefined)
                    } catch (err) {
                        // 如果转换失败，代表没有长数字可转，正常解析并返回
                        return JSON.parse(data)
                    }
                }
            ]
        };

        // 若是有做鉴权token , 就给头部带上token
        const { accessToken, refreshToken } = useToken();
        accessToken && (config.headers["Authorization"] = "Bearer " + accessToken);
        refreshToken && (config.headers["X-Authorization"] = "Bearer " + refreshToken);

        switch(method)
        {
            case "get":
            {
                config.params = Object.assign(request.data, config.params);

                break;
            }
            default:
            {
                let serializeType = !!request.serializeType ? request.serializeType : this.options.serializeType;

                if(serializeType === "form")
                {
                    config.headers["Content-Type"] = "application/x-www-form-urlencoded";

                    config.data = qs.stringify(request.data, { arrayFormat: "indices" });
                }
                else if(serializeType === "form-data")
                {
                    config.headers["Content-Type"] = "multipart/form-data";

                    let formData = new FormData();

                    let requestData = Object.assign(request.data);

                    for(let name in requestData)
                    {
                        if(requestData[name])
                        {
                            formData.append(name, request.data && request.data[name]);
                        }
                    }
                    
                    let requestFiles = request.files;

                    for(let name in requestFiles)
                    {
                        if(requestFiles[name])
                        {
                            formData.append(name, requestFiles[name]);
                        }
                    }

                    config.data = formData;
                }
                else if(serializeType === "application/x-www-form-urlencoded")
                {
                    config.headers["Content-Type"] = "application/x-www-form-urlencoded";

                    config.data = qs.stringify(request.data);
                }
                else
                {
                    config.headers["Content-Type"] = "application/json";

                    config.data = request.data;
                }

                break;
            }
        }

        // 初始化重试次数
        if(!request.retryCount)
        {
            request.retryCount = this.options.retryCount;
        }

        // 初始化重试间隔
        if(!request.retryInterval)
        {
            request.retryInterval = this.options.retryInterval;
        }

        // 设置上传进度回调函数
        if(Type.isFunction(request.onUploadProgress))
        {
            config.onUploadProgress = onUploadProgress;
        }

        // 设置下载进度回调函数
        if(Type.isFunction(request.onDownloadProgress))
        {
            config.onDownloadProgress = onDownloadProgress;
        }

        // 特意保存原始请求参数
        config["$request"] = request;

        return config;
    }

    /**
     * 发送请求。
     * @param  {AxiosRequestConfig} axiosRequest Axios 请求实例。
     * @returns Promise
     */
    private async send(axiosRequest: IAxiosRequestConfig): Promise<any>
    {
        const { accessToken, refreshToken, setLoadingCount } = useToken();
        HttpClient.count ++;
        setLoadingCount(HttpClient.count)
        return new Promise<any>((resolve, reject) =>
        {
            
            Axios(axiosRequest).then((axiosResponse: AxiosResponse) =>
            {
                this.onRequestComplete(axiosResponse, axiosRequest).then((content: any) =>
                {
                    HttpClient.count --;
                    setLoadingCount(HttpClient.count)
                    resolve(content);
                })
                .catch((error: any) =>
                {
                    HttpClient.count --;
                    setLoadingCount(HttpClient.count)
                    reject(error.content.msg || error.msg);
                });
            })
            .catch((error: any) =>
            {
                // 特意保存原始请求参数
                HttpClient.count --;
                setLoadingCount(HttpClient.count)
                let request: IHttpRequest = axiosRequest["$request"];

                let statusCode = error.response && error.response.status;
                reject(error.content.msg || error.msg);
            });
        });
    }
    
    /**
     * 当请求完毕时调用。
     * @param  {IHttpResponse} response 响应对象。
     * @param  {IAxiosRequestConfig} axiosRequest Axios 请求实例。
     * @returns Promise
     */
    private async onRequestComplete(axiosResponse: AxiosResponse | any, axiosRequest: IAxiosRequestConfig): Promise<any>
    {
        const response: any = this.resolveResponse(axiosResponse, axiosRequest,axiosResponse.code || axiosResponse.status);
        const request = response.request;
        const code = response.code;

        if(axiosResponse.headers["access-token"])
        {
            const { setAccessToken, setRefreshToken} = useToken();
            setAccessToken(axiosResponse.headers["access-token"]);
            setRefreshToken(axiosResponse.headers["x-access-token"]);
        }

        if(code === HttpResponseCode.ok || code === HttpResponseCode.successNotData)
        {
            // 请求成功，返回数据
            return Promise.resolve(response);
        }
        else if(code === HttpResponseCode.tokenInvalid)
        {
            // 403跳店铺
           
            router.push({name: "login"});
            return Promise.reject(response);
        }
        else if(code === HttpResponseCode.unauthorized)
        {
            const { setAccessToken } = useToken();
            // 401跳转登入
            localStorage.clear();
            setAccessToken(null);
            router.push({name: "login"});
            return Promise.reject(response);;
        }
        else
        {

            if(code === HttpResponseCode.error )
            {
                // 如果只是操作错误，则原封不动将响应返回
                return Promise.reject(response);
            }
            // 循环遍历处理函数进行处理
            this.handlers.forEach((handler: (code: number, response: IHttpResponse, request: IHttpRequest) => void | Promise<void>) =>
            {
                handler(code, response, request);
            });
            const entry = EnumUtils.getEntry(code, HttpResponseCode);
            response.msg = entry && entry.description;
            return Promise.reject(Object.assign({}, response, { msg: entry && entry.description }));
        }
    }

    /**
     * 解析 Axios 响应。
     * @param  {AxiosResponse} axiosResponse Axios 响应对象。
     * @param  {AxiosRequestConfig} axiosRequest Axios 请求实例。
     * @returns IHttpResponse HTTP 响应。
     */
    private resolveResponse(axiosResponse: AxiosResponse, axiosRequest: IAxiosRequestConfig, code: number): IHttpResponse
    {
        const request: IHttpRequest = axiosRequest["$request"];
        const { data: content = {}, headers = {} }: any = axiosResponse;
        const response: any =
        {
            code: content.code || code,
            request,
            data: axiosResponse.data,
            content:
            {
                extras: (axiosResponse.data as any).extras,
                data: content.data || null,
                msg: content.message
            },
            headers
        };
        return response;
    }

}
